@font-face {
  font-family: 'Lato-Bold';
  src: url('Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('Lato-Bold.woff') format('woff'),
    /* Modern Browsers */ url('Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('Lato-Light.woff2') format('woff2'),
    /* Modern Browsers */ url('Lato-Light.woff') format('woff'),
    /* Modern Browsers */ url('Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
