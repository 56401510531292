.section-header {
  padding: 2.5em 2.5em;

  .search-input {
    .search-icon {
      color: $primary-color;
    }

    .input-group-text {
      background-color: transparent;
      border-right: none;
    }
    .input-group-prepend {
      border-radius: $border-radius-large;
    }
    .form-control {
      border-left-color: transparent;
    }
    input {
      border-radius: $border-radius-large;
      // font-weight: regular;
      font-size: $font-input-place-holder;
      color: $black-color;
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $black-color;
        opacity: 1; /* Firefox */
      }
    }
  }
  .add-button {
    padding: 8px 8px;
    color: $primary-color;
    outline-color: $primary-color;
    border-color: $primary-color;
    border-radius: $border-radius-large;
    span {
      font-weight: $font-weight-semi;
    }
    &:hover {
      background-color: $primary-color;
      color: $white-color;
      border-color: $primary-color;
    }
  }
}
