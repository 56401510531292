$font-color: #103562 !default;
$fill-font-color: rgba(255, 255, 255, 0.8);
$font-family-sans-serif: 'Lato', Arial, sans-serif;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$white-color: #ffffff !default;
$white-bg: #f7fcff !default;
$white-states-color: rgba($white-color, 0.8);

$grey-color: #e0e0e0 !default;
$placeholder-gray: rgba(210, 210, 210, 1) !default;

$btn-grey: #656565;

$primary-color: #5bb8b4 !default;
$primary-states-color: darken($primary-color, 10%) !default;

$black-color: #06446a;

$accent-color: #a5c9ff !default;
$second-accent-color: #c3e2ff;

// set grid
$grid-gutter-width: 16px;

//== Components
//
$padding-base-vertical: 7px !default;
$padding-base-horizontal: 18px !default;

$padding-round-vertical: 9px !default;
$padding-round-horizontal: 18px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 18px !default;

$padding-large-vertical: 11px !default;
$padding-large-horizontal: 30px !default;

$padding-small-vertical: 4px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 2px !default;
$padding-xs-horizontal: 5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

// Radius
$margin-bottom: 0 0 10px 0 !default;
$border-radius-small: 3px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-x-large: 8px !default;
$border-radius-extreme: 12px !default;

// Font Sizes
$font-size-base: 16px !default;
$font-size-xsmall: 13px !default;
$font-size-small: 14px !default;
$font-size-almost-medium: 15px !default;
$font-size-medium: 16px !default;
$font-size-large: 20px !default;
$font-size-large-navbar: 24px !default;

$font-size-h1: 3.6em !default;
$font-size-h2: 2.8em !default;
$font-size-h3: 1.825em !default;
$font-size-h4: 1.6em !default;
$font-size-h5: 1.35em !default;
$font-size-h6: 0.9em !default;
$font-paragraph: 14px !default;
$font-size-navbar: 14px !default;
$font-input-place-holder: 15px !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 500 !default;
$font-weight-bold: 600 !default;

$line-height-small: 20px !default;
$line-height-general: 1.5em !default;
$line-height: 36px !default;
$line-height-lg: 54px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;

// refactor below

$fontColor: #103562;
$titleColor: #06446a;
$accentColor: #a5c9ff;
$popColor: #5bb8b4;
$whiteShade: #ffffff;
$background: #fafdff;
$borderGrey: #e0e0e0;
$formTopic: #103562;

$borderRadius: 15px;
