.form-topic-side {
  // border-bottom: 1px solid $accentColor;
  background-color: $font-color;
  color: $white-color;
  .title-section {
    padding-top: 0.5em;
    padding-left: 0.25em;
    .title-text {
      font-size: $font-size-large;
      padding-left: 0.5em;
    }
  }

  .topic-details {
    height: 100vh;
    padding-top: 1.5em;
    padding-right: 0;
    padding-left: 0;
    ul {
      padding-left: 0.5em;
      .complete {
        list-style-type: '\2713';
      }
      &.add li.incomplete {
        list-style-type: disc;
      }

      &:not(.add) li.incomplete {
        list-style-type: '\0021';
        color: red;
      }

      .active-complete {
        list-style-type: '\2713';
        color: $white-color;
      }
    }
    li {
      position: relative;
      padding-left: 1.5em; /* space to preserve indentation on wrap */
      padding-bottom: 15px;
      padding-top: 15px;
      font-size: $font-size-almost-medium;
      font-weight: $font-weight-light;
      list-style: none !important;
      .active {
        font-weight: $font-weight-semi;
        color: $white-color;
      }
    }
  }
}

.assessment-side {
  padding-left: 0em;

  .navbar-assessment-form {
    height: 5em;
    padding-top: 0.5em;
    .icon-unit-name-text {
      padding-left: 0.5em;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
    }

    background-color: $whiteShade;
    .avatar {
      max-height: 2.5em;
      min-height: 2.5em;
    }
    .text-cancel-save {
      font-size: $font-size-large;
      color: $primary-color;
    }
  }
}
