body {
  color: $font-color;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  font-family: $font-family-sans-serif;
  background-color: $white-bg;
}

.btn {
  border-radius: $border-radius-large;
}

.list-group {
  border-radius: $border-radius-large;
}
