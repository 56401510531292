@import 'partials/variables';
@import 'partials/typography';
@import 'partials/misc';

@import 'sections/nav';
@import 'sections/section-header';
@import 'sections/list-items';

@import 'sections/assessment';
@import 'sections/housing-detail';

@import 'components/buttons';
@import 'components/icons';
@import 'components/mui';

body {
  background-color: #f7fcff;
}

.page-notifications {
  padding-bottom: 50px;
}

.page-link {
  color: $popColor;
}

.questions {
  padding-bottom: 100px;

  .form-title {
    padding-top: 50px;
  }
}

.btn-continue,
.btn-continue:link {
  background-color: $popColor !important;
  box-shadow: none !important;
  padding-left: 100px;
  padding-right: 100px;
}

.btn-continue:visited,
.btn-continue:hover,
.btn-continue:active {
  background-color: #57a8a5 !important;
  box-shadow: none !important;
}

.question-area {
  margin-top: 50px;
}

.select-one-field {
  margin-top: 45px;
  .btn-select-one {
    padding: 30px 30px;
    // width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    width: 200px;
    .active {
      color: red;
    }
  }
}

.counter-field {
  margin-top: 45px;
  .btn-counter {
    width: 150px;
    padding: 20px 20px;
    background-color: $whiteShade;
    color: black;
    &:active {
      background-color: $whiteShade;
    }
  }

  .sgv-clicker {
    color: $popColor;
  }
  .right {
    padding-left: 30px;
  }
  .left {
    padding-right: 30px;
  }
}

.button-group-field {
  padding: 15px 20px;
  width: 80px;
}

.select-multi-field {
  margin-top: 45px;
}
.btn-multi-one {
  padding: 45px 45px;
  margin: 10px 10px 10px 10px;

  width: 250px;
  .active {
    color: red;
  }
}

.upload-file-widget {
  margin-top: 50px;
  background-color: $whiteShade;
  padding: 25px 50px 25px 50px;
  border-radius: $borderRadius;
  border: solid 1px $borderGrey;
  .file-drop-zone {
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: $borderRadius;
    border: dashed 1px $accentColor;
    background-color: $background;
  }
}

.image-gallery-selectfield {
  margin-top: 2em;
  margin-bottom: 2em;
}

.bedroom-detail {
  margin-top: 50px;
  border: 1px solid $accentColor;
  border-radius: 15px;
  padding: 25px 10px 25px 10px;
}

.inline-div {
  display: inline;
}
