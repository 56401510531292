.list-items {
  .table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
  }
  .table td,
  .table th {
    vertical-align: middle;
  }

  thead th {
    border: hidden;
  }

  tbody td,
  tbody th {
    border: 1px solid $accent-color;

    &:first-child {
      border-top-left-radius: $border-radius-large;
      border-bottom-left-radius: $border-radius-large;
      border-right: hidden;
      padding: 0px;
    }
    &:last-child {
      border-left: hidden;
      border-top-right-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
    }
    &:not(:first-child):not(:last-child) {
      border-left: hidden;
      border-right: hidden;
    }
  }
  .row-border {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border: 1px solid blue;
  }

  tr.list-item,
  .subject-sort {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-normal;
    color: $font-color;
    .regular-text {
      font-size: $font-size-xsmall;
    }
    .unit-details {
      h6 {
        font-weight: $font-weight-bold;
      }
    }
  }

  .img-card {
    border-top-left-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;
    max-width: 125px;
    max-height: 100px;
    padding: 0em;
  }
}
