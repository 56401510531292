.housing-detail {
  .return-units {
    padding-top: 2em;
    padding-bottom: 2em;
    color: $font-color;
    font-size: $font-size-small;
    .chevron-right {
      padding-right: 0.5em;
    }
  }

  .housing-info-panel {
    border: 1px solid $accentColor;
    background-color: $white-color;
    padding-bottom: 0;
    margin-bottom: 1.5em;
    max-height: 180px;
    .image-col {
      padding-left: 0em;
      // padding-right: -1em;
      margin-right: -1em;
      .img-card {
        padding-right: -1em;
        border-top-left-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
        max-width: 10em;
        max-height: 10em;
      }
    }

    .housing-summary {
      padding-top: 1em;
      padding-left: 0em;

      font-size: $font-size-almost-medium;
      h5 {
        font-weight: $font-weight-bold;
        color: $font-color;
        margin-bottom: 0.5em;
      }
      p {
        margin-bottom: 0.5em;
      }
      a,
      .street,
      .url {
        color: $primary-color;
      }
      .rent {
        color: $font-color;
        font-weight: $font-weight-light;
      }
    }
    .gallery-byline {
      padding-top: 1em;

      .gallery {
        color: $primary-color;
        font-weight: $font-weight-bold;
        font-size: $font-size-almost-medium;
        .gallery-svg {
          padding-right: 0.25em;
        }
      }
      .by-line {
        padding-top: 4em;
        font-weight: $font-weight-light;
        .author {
          padding-left: 0.25em;
          font-weight: $font-weight-bold;
        }
      }
    }
    .modal-xl {
      img-gallery {
        max-width: 600px;
        max-height: 600px;
      }
    }
  }

  .housing-status-panel {
    background-color: $white-color;
    border: 1px solid $accentColor;
    margin-bottom: 1.5em;

    .housing-status-content {
      padding-top: 1.5em;
      padding-bottom: 1.5em;

      .status {
        padding-left: 1.5em;
        h5 {
          font-weight: $font-weight-bold;
        }
      }
      .schedule-open-house {
        h6 {
          padding-bottom: 0em;
          font-weight: $font-weight-bold;
        }
        .btn {
          padding: 0.5em 1em;
          margin-left: 0.5em;
          border: 2px solid $accentColor;
        }
      }
    }
  }

  .housing-barriers {
    border-radius: $border-radius-large;
    border: 1px solid $accentColor;
    h5 {
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
      padding-bottom: 0.5em;
    }

    .card-body {
      padding-bottom: 0em;
    }

    .table th {
      padding-left: 0em;
      color: $font-color;
      font-size: $font-size-xsmall - 1;
      border-color: $accent-color;
    }
    .table td {
      padding-left: 0em;
      color: $font-color;
      font-size: $font-size-almost-medium;
      border-color: $accent-color;
    }
    .card-footer {
      background-color: $white-color;
      border-top: none;
      p {
        border-radius: $border-radius-large;
        background-color: $second-accent-color;
        font-size: $font-size-xsmall - 1;
        padding: 1em;
      }
    }
  }

  .housing-more-detail-panel {
    background-color: $white-color;
    border: 1px solid $accent-color;
    margin-bottom: 1.5em;
    padding: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 0.5em;

    .housing-more-detail-content {
      .header {
        .title {
          font-weight: $font-weight-bold;
          font-size: $font-size-large;
          padding-bottom: 1em;
          padding-left: 0.5em;
        }
        .edit-link {
          a {
            text-align: right;
            color: $primary-color;
            font-weight: $font-weight-bold;
          }
        }
      }

      .item-names {
        .table td,
        .table tr {
          text-align: left;
          color: $font-color;
        }
        .item-name {
          font-size: $font-size-xsmall - 1;
          overflow: hidden;
          white-space: nowrap;
        }
        .item-value {
          font-size: $font-size-almost-medium;

          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .housing-notes-panel {
    margin-bottom: 1.5em;
    padding: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 0.5em;
    background-color: $white-color;
    border: 1px solid $accent-color;
    border-radius: $border-radius-large;
    margin-bottom: 25px;
    min-height: 300px;
    .input-group {
      padding-top: 0.5em;
      padding-bottom: 2em;
      .text-input-area {
        border-radius: $border-radius-large;
        color: $font-color;
        &::placeholder {
          padding-top: 0.75em;
          padding-left: 1em;
        }
      }

      .note-button {
        // padding: 0.5em 1em;
        padding-left: 3em;
        padding-right: 3em;
        color: $white-color;
      }
    }
    .notes-table {
      h6 {
        padding-bottom: 0.75em;
      }
      .table td,
      .table tr,
      .table th {
        border-color: $accentColor;
        color: $font-color;
        h6 {
          font-weight: $font-weight-bold;
          margin-bottom: 0em;
        }
        .time-ago {
          width: 18%;
        }
      }
      .table th {
        padding-top: 1em;
        padding-right: 0em;
        // margin-right: -1em;
      }
    }
  }
}
