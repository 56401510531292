.nav-main {
  border-bottom: 1px solid $accent-color;
  background-color: $white-color;
  .avatar {
    max-height: 32px;
    min-height: 32px;
  }
  img.brand-logo {
    width: 120%;
    height: auto;
  }
}
nav {
  .nav-item,
  .nav-link {
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
    color: $font-color;

    a.active {
      color: $primary-color;
    }
    span {
      font-size: $font-size-large;
    }
    span.active {
      padding-bottom: 0.25em;
      border-bottom: 2px solid $primary-color;
    }
  }
}
@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 2em;
  }
  .navbar li {
    margin-left: 1em;
    margin-right: 1em;
  }
}
