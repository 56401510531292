.btn-action {
  color: $whiteShade;
  background-color: $popColor;
  border-color: $popColor;
  &:hover {
    background-color: $whiteShade;
    color: $popColor;
    border-color: $popColor;
  }
}
